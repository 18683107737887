import React, { useContext } from 'react';
import { BuilderContext } from '../App';
import { Button, Collapse } from 'antd';
import SectionEditor from './SectionEditor';
import { AlignLeftOutlined, AlignRightOutlined } from '@ant-design/icons';
import { updateMap } from '../base';

const { Panel } = Collapse;

const LevelEditor = () => {
    const builder = useContext(BuilderContext);

    const renumberSections = async (lIndex, useLetters, attrType, backward) => {
        const A = 65;
        const Z = 90;
        const copy = { ...builder.mapDoc.json.map };

        const calcLetter = (i) => {
            const num = backward ? sectionsLength - i - 1 : i;
            if (num === 0) {
                return String.fromCharCode(A);
            } else if (num < Z) {
                return String.fromCharCode(num + A);
            } else {
                const single = String.fromCharCode(num + A);
                return single + single;
            }
        };

        const sectionsLength = copy.level[lIndex].section.length;
        copy.level[lIndex].section.forEach((section, i) => {
            copy.level[lIndex].section[i] = {
                ...section,
                attr: {
                    ...section.attr,
                    [attrType]: useLetters
                        ? calcLetter(i)
                        : `${
                              backward
                                  ? (sectionsLength - i).toString()
                                  : (i + 1).toString()
                          }`
                }
            };
        });
        await updateMap(copy, builder.mapDoc.id);
    };

    return (
        <Collapse>
            {builder.mapDoc.json.map.level &&
                builder.mapDoc.json.map.level.map((level, lIndex) => (
                    <Panel
                        key={`${level.attr.cr_level}_${lIndex}`}
                        header={`Level ${level.attr.cr_level} - ${level.attr.name}`}
                    >
                        <div>
                            <span>Section CR: </span>
                            <Button
                                onClick={() =>
                                    renumberSections(
                                        lIndex,
                                        false,
                                        'cr_section',
                                        false
                                    )
                                }
                                shape='circle'
                                icon={<AlignLeftOutlined />}
                            />
                            &nbsp;
                            <Button
                                onClick={() =>
                                    renumberSections(
                                        lIndex,
                                        false,
                                        'cr_section',
                                        true
                                    )
                                }
                                shape='circle'
                                icon={<AlignRightOutlined />}
                            />
                        </div>
                        <br />
                        <div>
                            <span>Section View (numbers): </span>
                            <Button
                                onClick={() =>
                                    renumberSections(
                                        lIndex,
                                        false,
                                        'number',
                                        false
                                    )
                                }
                                shape='circle'
                                icon={<AlignLeftOutlined />}
                            />
                            &nbsp;
                            <Button
                                onClick={() =>
                                    renumberSections(
                                        lIndex,
                                        false,
                                        'number',
                                        true
                                    )
                                }
                                shape='circle'
                                icon={<AlignRightOutlined />}
                            />
                        </div>
                        <br />
                        <div>
                            <span>Section View (letters): </span>
                            <Button
                                onClick={() =>
                                    renumberSections(
                                        lIndex,
                                        true,
                                        'number',
                                        false
                                    )
                                }
                                shape='circle'
                                icon={<AlignLeftOutlined />}
                            />
                            &nbsp;
                            <Button
                                onClick={() =>
                                    renumberSections(
                                        lIndex,
                                        true,
                                        'number',
                                        true
                                    )
                                }
                                shape='circle'
                                icon={<AlignRightOutlined />}
                            />
                        </div>
                        <br />
                        {level.section &&
                            level.section.map((section, sIndex) => (
                                <SectionEditor
                                    key={`l${level.attr.cr_level}s${section.attr.cr_section}`}
                                    levelCr={level.attr.cr_level}
                                    lIndex={lIndex}
                                    section={section}
                                    sIndex={sIndex}
                                />
                            ))}
                    </Panel>
                ))}
        </Collapse>
    );
};

export default LevelEditor;
