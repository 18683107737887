const newElement = (vals) => ({
    svg: [
        {
            attr: {
                width: vals.width,
                height: vals.height
            },
            rect: [
                {
                    attr: {
                        fill: '#C4C4C4',
                        width: vals.width - 2,
                        height: vals.height - 2,
                        strokeWidth: 2,
                        stroke: '#4F4F4F',
                        x: 1,
                        y: 1,
                        rx: '8'
                    }
                }
            ],
            text: [
                {
                    attr: {
                        x: vals.width / 2,
                        y: vals.height * 0.6,
                        fontWeight: 'bold',
                        fill: '#4F4F4F',
                        fontSize: '38',
                        textAnchor: 'middle'
                    },
                    _: vals.name
                }
            ]
        }
    ]
});

export default newElement;
