import { Modal, Form, Input, InputNumber, Button, Space, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';

const { Option } = Select;
const A = 65;
const Z = 90;

const AddRowsModal = ({ visible, setVisible, section, addRows }) => {
    const onFinish = (values) => {
        console.log(values);

        const newRows = values.rows.map((item, i) => ({
            attr: {
                name: item.row,
                cr_row: section.row ? section.row.length + i : i + 1
            },
            seat: Array.from(Array(item.seats)).map((item, i) => ({
                attr: {
                    number: i + 1,
                    cr_seat: i + 1
                }
            }))
        }));
        // console.log(newRows);
        addRows(newRows);
    };

    const onQuickFinish = (values) => {
        console.log(values);

        const newRows = Array.from(Array(values.rows)).map((item, i) => ({
            attr: {
                name:
                    values.sectionNums === 'numbers'
                        ? i + 1
                        : calcLetter(i, values.sectionNums === 'double'),
                cr_row: section.row ? section.row.length + i + 1 : i + 1
            },
            seat: Array.from(Array(values.seats)).map((item, i) => ({
                attr: {
                    number: i + 1,
                    cr_seat: i + 1
                }
            }))
        }));
        // console.log(newRows);
        addRows(newRows);
    };

    const calcLetter = (i, isDouble) => {
        const single = String.fromCharCode(i + A);
        return isDouble || i > Z ? `${single}${single}` : single;
    };

    return (
        <Modal
            title='Add Rows and Seats'
            visible={visible}
            onOk={onFinish}
            onCancel={() => setVisible(false)}
            footer={false}
        >
            <Form
                name='quickCreate'
                onFinish={onQuickFinish}
                {...{
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14 }
                }}
            >
                Quick Create
                <Form.Item label='Section Numbering' name='sectionNums'>
                    <Select>
                        <Option value='letters'>Letters</Option>
                        <Option value='numbers'>Numbers</Option>
                        <Option value='double'>Double Letters</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Number of Rows' name='rows'>
                    <InputNumber />
                </Form.Item>
                <Form.Item label='Number of Seats' name='seats'>
                    <InputNumber />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            <div style={{ width: '100%', borderBottom: '1px dashed gray' }} />
            <br />
            <Form name='nestedForm' onFinish={onFinish} autoComplete='off'>
                Enter every row
                <Form.List name='rows'>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <Space
                                    key={field.key}
                                    style={{ display: 'flex', marginBottom: 8 }}
                                    align='baseline'
                                >
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'row']}
                                        fieldKey={[field.fieldKey, 'row']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing row name'
                                            }
                                        ]}
                                    >
                                        <Input placeholder='Row Label' />
                                    </Form.Item>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'seats']}
                                        fieldKey={[field.fieldKey, 'seats']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Number of seats required'
                                            }
                                        ]}
                                    >
                                        <InputNumber placeholder='Seats' />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        onClick={() => remove(field.name)}
                                    />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button
                                    type='dashed'
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Add field
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary' htmlType='submit'>
                                    Submit
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
};

export default AddRowsModal;
