import { Button, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { EditorContext } from '../../screens/Builder';
import removeEncoding from './removeEncoding';
import Row from './Row';
import SectionElement from './SectionElement';
import {
    PlusOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    CaretDownOutlined,
    UndoOutlined,
    DeleteOutlined,
    AlignCenterOutlined
} from '@ant-design/icons';
import AddRowsModal from '../../modals/addRowsModal';
import { updateMap } from '../../base';

const { Paragraph } = Typography;

const Section = ({ section, identity, indexes }) => {
    const [sectionStyles, setSectionStyles] = useState({});
    const [isElement, setIsElement] = useState(false);
    const [active, setActive] = useState(false);
    const [addRowsModalVis, setAddRowsModalVis] = useState(false);
    const [id] = useState({
        ...identity,
        section: section.attr.cr_section,
        view: { ...identity.view, section: section.attr.number }
    });
    const [bulkSeatStyles, setBulkSeatStyles] = useState({});
    const editor = useContext(EditorContext);
    const [label, setLabel] = useState(section.attr.number);
    const [origin, setOrigin] = useState('left');
    const [rotation, setRotation] = useState(0);
    const [rotateLocation, setRotateLocation] = useState('');

    useEffect(() => {
        if (section.attr.rotate) {
            setRotation(Number(section.attr.rotate) || 0);
        } else if (section.attr.styles) {
            const stylesJson = JSON.parse(section.attr.styles);
            const found =
                stylesJson.transform && stylesJson.transform.match(/-?\d+/);
            setRotation(Number(found && found[0]) || 0);
        }
    }, []);

    useEffect(() => {
        if (section.attr.styles) {
            setSectionStyles(removeEncoding(section.attr.styles || ''));
        }
    }, [section.attr.styles]);

    useEffect(() => {
        const half = editor.seatingMap.level[indexes.level].section.length / 2;
        if (indexes.section > half) {
            setOrigin('right');
        } else if (indexes.section === half) {
            setOrigin('center');
        } else if (indexes.section < half) {
            setOrigin('left');
        }
    }, [indexes]);

    useEffect(() => {
        setRotateLocation(section.attr.rotate ? 'rows' : 'section');

        if (section.attr.rotate || section.attr.alignment) {
            setBulkSeatStyles({
                alignment: section.attr.alignment
                    ? section.attr.alignment
                    : 'flex-start',
                rotate: section.attr.rotate
                    ? `rotate(${section.attr.rotate}deg)`
                    : 'rotate(0deg)'
            });
        }

        setLabel(section.attr.number);
    }, [section.attr]);

    useEffect(() => {
        if (section.svg) {
            setIsElement(true);
        }
    }, [section.svg]);

    useEffect(() => {
        if (label !== section.attr.number) {
            console.log('label changed');
            section.attr.number = label;
            updateMap(editor.seatingMap, editor.mapId);
        }
    }, [label]);

    useEffect(() => {
        const stylesJson = { ...JSON.parse(section.attr.styles || '{}') };

        if (rotateLocation === 'section') {
            stylesJson.transform = `rotate(${rotation}deg)`;
            if (!stylesJson.transformOrigin) {
                stylesJson.transformOrigin = `top ${
                    origin === 'left' ? 'right' : 'left'
                }`;
            }
            delete section.attr.rotate;
        } else if (rotateLocation === 'rows') {
            section.attr.rotate = rotation;
            stylesJson.transform = `rotate(0deg)`;
            for (const row of section.row) {
                const temp = { ...JSON.parse(row.attr.styles || '{}') };
                if (!stylesJson.transformOrigin) {
                    temp.transformOrigin = `top ${
                        origin === 'left' ? 'right' : 'left'
                    }`;
                }

                row.attr.styles = JSON.stringify(temp);
            }
        }

        section.attr.styles = JSON.stringify(stylesJson);
        updateMap(editor.seatingMap, editor.mapId);
    }, [rotation, rotateLocation]);

    const markAsActive = (event) => {
        setActive(!active);

        const thisSection = editor.activeEdit.findIndex(
            (item) =>
                item.level === identity.level &&
                item.section === section.attr.cr_section
        );

        if (thisSection === -1) {
            editor.setActiveEdit([
                ...editor.activeEdit,
                {
                    level: identity.level,
                    section: section.attr.cr_section
                }
            ]);
        } else if (editor.activeEdit.length > 1) {
            const copy = [...editor.activeEdit];
            copy.splice(thisSection, 1);
            editor.setActiveEdit(copy);
        } else {
            editor.setActiveEdit([]);
        }
    };

    const addRows = (rowsAndSeats) => {
        const copy = { ...editor.seatingMap };
        const rows =
            copy.level[indexes.level].section[indexes.section].row || [];
        copy.level[indexes.level].section[indexes.section].row = [
            ...rows,
            ...rowsAndSeats
        ];
        updateMap(copy, editor.mapId);
        setAddRowsModalVis(false);
    };

    const deleteSection = () => {
        const copy = { ...editor.seatingMap };
        copy.level[indexes.level].section.splice(indexes.section, 1);
        if (copy.level[indexes.level].section.length === 0) {
            copy.level.splice(indexes.level, 1);
        }
        updateMap(copy, editor.mapId);
    };

    const changeOrientation = (startFrom) => {
        console.log(startFrom);
        const copy = { ...editor.seatingMap };

        copy.level[indexes.level].section[
            indexes.section
        ].attr.alignment = startFrom;

        if (startFrom !== 'center') {
            copy.level[indexes.level].section[indexes.section].row.forEach(
                (row, i) => {
                    const seatsLength =
                        row.seat.filter((item) => !item.attr.invisible).length +
                        1;

                    let num = 0;
                    let blockedNum = 0;

                    copy.level[indexes.level].section[indexes.section].row[
                        i
                    ].seat = row.seat.map((seat) => {
                        if (seat.attr.invisible || seat.attr.disabled) {
                            blockedNum += 100;
                            return {
                                ...seat,
                                attr: {
                                    ...seat.attr,
                                    number: blockedNum
                                }
                            };
                        }

                        num += 1;
                        return {
                            ...seat,
                            attr: {
                                ...seat.attr,
                                number:
                                    startFrom === 'flex-end'
                                        ? `${seatsLength - num}`
                                        : `${num}`
                            }
                        };
                    });
                }
            );
        }

        updateMap(copy, editor.mapId);
    };

    const rotateSection = (direction) => {
        if (
            (direction === 'up' && origin === 'left') ||
            (direction === 'down' && origin === 'right')
        ) {
            setRotation(rotation + 2);
        } else if (
            (direction === 'up' && origin === 'right') ||
            (direction === 'down' && origin === 'left')
        ) {
            setRotation(rotation + -2);
        }
    };

    const toggleRotateLocation = () => {
        setRotateLocation(rotateLocation === 'rows' ? 'section' : 'rows');
    };

    const removeRow = (index) => {
        console.log('remove row with index', index);
        const copy = { ...editor.seatingMap };
        copy.level[indexes.level].section[indexes.section].row.splice(index, 1);

        // updateMap(copy, editor.mapId);
    };

    if (isElement) {
        return (
            <SectionElement
                section={section}
                styles={sectionStyles}
                indexes={indexes}
            />
        );
    }

    return (
        <div
            style={sectionStyles}
            className={`cr-section ${active ? 'active' : ''}`}
        >
            <AddRowsModal
                visible={addRowsModalVis}
                setVisible={setAddRowsModalVis}
                section={section}
                addRows={addRows}
            />
            <div
                className='cr-section-label'
                style={{
                    top:
                        rotateLocation === 'rows'
                            ? `-${Math.abs(rotation) * 2.5 + 5}px`
                            : '0px'
                }}
                onClick={markAsActive}
            >
                <Paragraph editable={{ onChange: setLabel }}>{label}</Paragraph>
            </div>
            <div className='rows'>
                {active && (
                    <div
                        className='addBtn'
                        style={{
                            position: 'relative',
                            top:
                                rotateLocation === 'rows'
                                    ? `-${Math.abs(rotation) * 2 + 5}px`
                                    : '0px',
                            zIndex: 99
                        }}
                    >
                        <Button
                            onClick={() => deleteSection()}
                            shape='circle'
                            icon={<DeleteOutlined />}
                        />
                        <Button
                            onClick={() => setAddRowsModalVis(true)}
                            shape='circle'
                            icon={<PlusOutlined />}
                        />
                        {section.row && section.row.length > 0 && (
                            <>
                                <Button
                                    onClick={() =>
                                        changeOrientation('flex-start')
                                    }
                                    shape='circle'
                                    icon={<AlignLeftOutlined />}
                                />
                                <Button
                                    onClick={() => changeOrientation('center')}
                                    shape='circle'
                                    icon={<AlignCenterOutlined />}
                                />
                                <Button
                                    onClick={() =>
                                        changeOrientation('flex-end')
                                    }
                                    shape='circle'
                                    icon={<AlignRightOutlined />}
                                />
                                <Button
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        [origin]: '5px'
                                    }}
                                    onClick={() => rotateSection('up')}
                                    shape='circle'
                                >
                                    {rotation}&#176;
                                </Button>
                                <Button
                                    style={{
                                        position: 'absolute',
                                        top: '38px',
                                        [origin]: '5px'
                                    }}
                                    onClick={() => rotateSection('down')}
                                    shape='circle'
                                    icon={<CaretDownOutlined />}
                                />
                                <Button
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        [origin === 'left'
                                            ? 'right'
                                            : 'left']: '5px'
                                    }}
                                    onClick={toggleRotateLocation}
                                    shape='circle'
                                    icon={<UndoOutlined />}
                                />
                            </>
                        )}
                    </div>
                )}
                {section.row &&
                    section.row.map((row, i) => (
                        <Row
                            key={`row_${i}`}
                            row={row}
                            identity={id}
                            bulkSeatStyles={bulkSeatStyles}
                            removeRow={() => removeRow(i)}
                        />
                    ))}
            </div>
        </div>
    );
};

export default Section;
