import React from 'react';

import Details from './Details';

const ChildrenList = ({ list, secondList, type, updateJson }) => {
    const updateList = (item, index) => {
        const temp = list.map((listItem, i) => {
            if (i === index) {
                return item;
            }
            return listItem;
        });
        updateJson(temp);
    };
    const updateList2 = (item, index) => {
        const temp = secondList.map((listItem, i) => {
            if (i === index) {
                return item;
            }
            return listItem;
        });

        updateJson(temp, true);
    };

    if (list === undefined) {
        return <div />;
    }

    return (
        <div className='ChildrenList'>
            {list.map((item, i) => (
                <Details
                    json={item}
                    key={`${i}${item.attr.name || item.attr.number}`}
                    type={type}
                    index={i}
                    updateJson={(json) => updateList(json, i)}
                />
            ))}
            {/* Hardwired for SVG rect */}
            {secondList.length > 0 &&
                secondList.map((sItem, i) => (
                    <Details
                        json={sItem}
                        key={`${i}${sItem.attr.name || sItem.attr.number}`}
                        type={'Rectagle'}
                        index={i}
                        updateJson={(json) => updateList2(json, i)}
                    />
                ))}
        </div>
    );
};

export default ChildrenList;
