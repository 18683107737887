const newElementLabel = (vals) => ({
    attr: {
        width: vals.width,
        height: vals.height
    },
    text: [
        {
            attr: {
                x: vals.width / 2,
                y: vals.height * 0.6,
                fontWeight: 'bold',
                fill: '#4F4F4F',
                fontSize: vals.fontSize,
                textAnchor: 'middle'
            },
            _: vals.name
        }
    ]
});

export default newElementLabel;
