import React, { useState } from 'react';
import { Collapse, Typography } from 'antd';
// import { BuilderContext } from '../App';
// import { updateMap } from '../base';

const { Panel } = Collapse;
const { Paragraph } = Typography;

const RowEditor = ({ sectionCr, row }) => {
    const [rowLabel, setRowLabel] = useState(row.attr.name);
    const [crNumber, setCrNumber] = useState(row.attr.cr_row);

    return (
        <Collapse>
            <Panel
                key={`s${sectionCr}r${row.attr.cr_row}`}
                header={`Row ${row.attr.cr_row} - ${row.attr.name}`}
            >
                CR Number:{' '}
                <Paragraph editable={{ onChange: setCrNumber }}>
                    {crNumber}
                </Paragraph>
                Visible Number:{' '}
                <Paragraph editable={{ onChange: setRowLabel }}>
                    {rowLabel}
                </Paragraph>
            </Panel>
        </Collapse>
    );
};

export default RowEditor;
