import React, { createRef } from 'react';

// import { EditorContext } from '../../screens/Builder';
// import { updateMap } from '../../base';
// import interact from 'interactjs';

const SectionElement = ({ section, styles }) => {
    const parentRef = createRef();
    const textRef = createRef();
    // const editor = useContext(EditorContext);

    // useEffect(() => {
    //     if (parentRef) {
    //         interact(`.elementHover`).draggable({
    //             listeners: {
    //                 start(event) {
    //                     editor.setMovingItem(true);
    //                 },
    //                 move(event) {
    //                     const target = event.target;
    //                     const dataX = target.getAttribute('data-x');
    //                     const dataY = target.getAttribute('data-y');
    //                     const initialX = parseFloat(dataX) || 0;
    //                     const initialY = parseFloat(dataY) || 0;

    //                     const deltaX = event.dx;
    //                     const deltaY = event.dy;

    //                     const newX = initialX + deltaX;
    //                     const newY = initialY + deltaY;

    //                     target.style.left = `${newX}px`;
    //                     target.style.top = `${newY}px`;

    //                     target.setAttribute('data-x', newX);
    //                     target.setAttribute('data-y', newY);
    //                 },
    //                 end(event) {
    //                     console.log('finish');
    //                     const target = event.target;

    //                     const dataX = target.getAttribute('data-x');
    //                     const dataY = target.getAttribute('data-y');
    //                     const initialX = parseFloat(dataX) || 0;
    //                     const initialY = parseFloat(dataY) || 0;

    //                     const deltaX = event.dx;
    //                     const deltaY = event.dy;

    //                     const newX = initialX + deltaX;
    //                     const newY = initialY + deltaY;

    //                     const newPos = JSON.parse(section.attr.styles);

    //                     newPos.left = `${newX}px`;
    //                     newPos.top = `${newY}px`;

    //                     target.setAttribute('data-x', 0);
    //                     target.setAttribute('data-y', 0);

    //                     section.attr.styles = JSON.stringify(newPos);
    //                     updateMap(editor.seatingMap, editor.mapId);
    //                     editor.setMovingItem(false);
    //                 }
    //             }
    //         });
    //     }
    // }, [parentRef.current, textRef.current]);

    return (
        <div
            className={`element ${section.attr.number} elementHover`}
            style={styles}
            ref={parentRef}
        >
            {section.svg && (
                <svg {...section.svg[0].attr}>
                    {section.svg[0].rect &&
                        section.svg[0].rect.map((rect, i) => (
                            <rect key={i} {...rect.attr} />
                        ))}
                    {section.svg[0].text &&
                        section.svg[0].text.map((text, i) => (
                            <text key={i} {...text.attr} ref={textRef}>
                                {text._}
                            </text>
                        ))}
                </svg>
            )}
        </div>
    );
};

export default SectionElement;
