import React, { useEffect, useState } from 'react';
import { updateMap } from '../../base';
import Details from './Details';

const Explorer = ({ json, mapId }) => {
    // let newJson = { ...json };
    const [newJson, setNewJson] = useState(json);

    useEffect(() => {
        if (json) {
            setNewJson(json);
        }
    }, [json]);

    /*  json Schema
     *   {
     *       attr: {} - Object that holds attributes about the current object
     *       `[level|section|row|seat|svg]`: [] - an array of level, section, row, or seat
     *   }
     */

    const updateObj = (updatedJson) => {
        updateMap(updatedJson, mapId);
    };

    return (
        <div className='explorer root'>
            <Details
                json={newJson}
                type='Seating Map'
                index={0}
                updateJson={updateObj}
            />
        </div>
    );
};

export default Explorer;
