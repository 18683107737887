export default {
    map: {
        attr: {
            verision: 2,
            styles: JSON.stringify({
                display: 'flex',
                flexFlow: 'column nowrap'
            })
        },
        level: []
    }
};
