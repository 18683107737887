import React, { useEffect, useState } from 'react';
import removeEncoding from './removeEncoding';
import Section from './Section';

const Level = ({ level, indexes }) => {
    const seatSchema = {
        level: level.attr.cr_level,
        view: {
            level: level.attr.name
        }
    };
    const [levelStyles, setLevelStyles] = useState({});

    useEffect(() => {
        if (level.attr.styles) {
            setLevelStyles(removeEncoding(level.attr.styles));
        }
    }, [level.attr.styles]);

    return (
        <div style={levelStyles} className='cr-level'>
            {level.section.map((section, i) => (
                <Section
                    key={`section_${i}`}
                    section={section}
                    identity={seatSchema}
                    indexes={{ ...indexes, section: i }}
                />
            ))}
        </div>
    );
};

export default Level;
