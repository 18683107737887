import React from 'react';
import { Slider } from 'antd';

const BuilderSlider = ({ title, initialValue = 0, setValue, onDone, max }) => {
    return (
        <div style={{ margin: '5px 0px' }}>
            <div>{title}</div>
            <Slider
                defaultValue={initialValue}
                onChange={setValue}
                onAfterChange={onDone}
                max={max}
            />
        </div>
    );
};

export default BuilderSlider;
