import { Button, Input, Form, Card } from 'antd';
import React from 'react';
// import { DetailsContext } from '../screens/CommandCenter';

const DetailsForm = ({ details, attrType, viewParam, onSave }) => {
    // const detailsCard = useContext(DetailsContext);

    const onFinish = async (values) => {
        console.log('update map plz:', values);
        onSave(values);
    };

    return (
        <Card
            className='details-form'
            title={`${attrType}: ${details[viewParam]}`}
        >
            <Form
                layout={'vertical'}
                name='basic'
                initialValues={details}
                onFinish={onFinish}
            >
                <Form.Item
                    label='CR Number'
                    name={`cr_${attrType}`}
                    rules={[
                        {
                            required: true,
                            message: 'Required'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Name'
                    name={viewParam}
                    rules={[
                        {
                            required: true,
                            message: 'Required'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button
                        htmlType='button'
                        // onClick={() => detailsCard.setDetails({})}
                    >
                        Cancel
                    </Button>
                    &nbsp;
                    <Button type='primary' htmlType='submit'>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default DetailsForm;
