// import { useState, useEffect } from 'react';
// import xmlFile from '../hyde-park-map-11.xml';

const getTextFile = async (fileUrl) => {
    const file = await window.fetch(fileUrl);
    const body = await file.text();
    return body;
};

export default getTextFile;
