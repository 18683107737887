import { Button, Input, Modal, Select, Form } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useContext, useEffect, useState } from 'react';
import { BuilderContext } from '../App';
import app, { writeDoc } from '../base';
import { nanoid } from 'nanoid';

const { Option } = Select;

const SaveAsModal = ({ visible, cancel, currentName, saveFile }) => {
    const builder = useContext(BuilderContext);
    const [churches, setChurches] = useState([]);
    const [folder, setFolder] = useState('');
    const [selectedChurch, setSelectedChurch] = useState();
    const [nameValidator, setNameValidator] = useState([]);
    const [name, setName] = useState(currentName);
    const [includeSection, setIncludeSection] = useState(false);
    const [loading, setLoading] = useState(false);

    console.log('builder', builder);

    useEffect(() => {
        app.firestore()
            .collection('accounts')
            .get()
            .then((snap) => {
                setChurches(
                    snap.docs.map((doc) => {
                        return doc.data();
                    })
                );
            })
            .catch((error) => console.error(error));
    }, []);

    const findMatchingMap = (maps, buildingMapId) => {
        return maps.findIndex((item) => item.id === buildingMapId);
    }

    const saveAs = async () => {
        setLoading(true);
        const url = await saveFile(folder, name);
        console.log(url);

        // If successfully created file
        if (url) {
            const existingMapIndex = findMatchingMap(selectedChurch.maps, builder.mapDoc.mapId);
            const newMapJson = {
                name: name,
                url: url,
                seatNumbering: {
                    view: true,
                    section: includeSection ?? false,
                    row: true,
                    seat: true,
                    separator: '-'
                }
            }

            if(existingMapIndex > -1) {
                const newId = nanoid(8);
                newMapJson.id = newId;
            }


            await writeDoc('accounts', selectedChurch.url, {
                ...selectedChurch,
                maps: [
                    ...selectedChurch.maps,
                    newMapJson
                ]
            });
            // if mapBuilder has a url, create a new builder file
            console.log(builder.mapDoc.mapUrl);
            if (builder.mapDoc.mapUrl) {
                const doc = await writeDoc(
                    'mapBuilds',
                    null,
                    {
                        ...builder.mapDoc,
                        mapId: newMapJson.id, // make sure we use the most updated id for the map
                        mapName: name,
                        church: folder,
                        mapUrl: url
                    },
                    false
                );
                builder.setMapKey(doc.id);
            } else {
                await writeDoc(
                    'mapBuilds',
                    builder.mapDoc.id,
                    {
                        mapUrl: url
                    },
                    true
                );
            }
        }
        cancel(true);
        setLoading(false);
    };

    useEffect(() => {
        if (folder) {
            setSelectedChurch(churches.find((chur) => chur.url === folder));
        }
    }, [folder]);

    useEffect(() => {
        if (selectedChurch) {
            setNameValidator(
                selectedChurch.maps.filter((map) => map.name === name)
            );
        }
    }, [name, selectedChurch]);

    const filter = (input, option) => {
        return (
            option.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
            option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    };

    return (
        <Modal
            title='Save As...'
            visible={visible}
            onOk={saveAs}
            onCancel={cancel}
            confirmLoading={loading}
            footer={[
                <Button key='back' onClick={cancel}>
                    Return
                </Button>,
                <Button key='submit' type='primary' onClick={saveAs}>
                    Submit
                </Button>
            ]}
        >
            <Form layout='vertical'>
                <Form.Item label='Select a Church'>
                    <Select
                        showSearch
                        onChange={setFolder}
                        style={{ width: '100%' }}
                        filterOption={filter}
                    >
                        {churches.map((church) => (
                            <Option key={church.url} value={church.url}>
                                {church.name} - {church.url}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    validateStatus={
                        nameValidator.length > 0 ? 'error' : 'validating'
                    }
                    label='Name this seating map'
                    help={
                        nameValidator.length > 0
                            ? 'There already a map with this name for this church'
                            : 'This must be unique'
                    }
                >
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Checkbox
                        defaultChecked={false}
                        onChange={(e) => setIncludeSection(e.target.checked)}
                    >
                        Include section in the seat number
                    </Checkbox>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SaveAsModal;
