import React, { useContext, useEffect } from 'react';
import { BuilderContext } from '../App';
import useBuilderSnapshot from '../hooks/builderSnapshot';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";

import Editor from './Editor';
import Builder from './Builder';

const CommandCenter = () => {
    let params = useParams();
    const builder = useContext(BuilderContext);
    const mapDoc = useBuilderSnapshot(params.uuid);
    const navigate = useNavigate();

    useEffect(() => {
        if (mapDoc) {
            builder.setMapDoc(mapDoc);
        }
    }, [mapDoc]);

    const startOver = () => {
        builder.setMapDoc({});
        builder.setMapKey('');
        navigate(`../`);
    };

    const goBack = () => {
        navigate(`/command/${builder.mapDoc.id}`);
    };

    return (
        <Routes>
            <Route path="/" element={<Editor startOver={startOver} />} />
            <Route path="builder" element={<Builder goBack={goBack} />} />
        </Routes>
    );
};

export default CommandCenter;
