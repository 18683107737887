import React, { useContext, useEffect, useState } from 'react';
import { EditorContext } from '../../screens/Builder';
import removeEncoding from './removeEncoding';

const Seat = ({ seat, identity }) => {
    const [seatStyles, setSeatStyles] = useState({});
    const [statuses, setStatuses] = useState({});
    const [id, setId] = useState({
        ...identity,
        seat: seat.attr.cr_seat,
        view: { ...identity.view, seat: seat.attr.number }
    });
    const editor = useContext(EditorContext);

    useEffect(() => {
        setStatuses({
            invisible: seat.attr.invisible || false,
            handicap: seat.attr.handicap || false,
            disabled: seat.attr.disabled || false
        });

        setId({
            ...identity,
            seat: seat.attr.cr_seat,
            view: { ...identity.view, seat: seat.attr.number }
        });
    }, [seat]);

    useEffect(() => {
        if (seat.attr.styles) {
            setSeatStyles(removeEncoding(seat.attr.styles));
        }
    }, [seat.attr.styles]);

    const getSeatText = () => {
        const number = editor.showCRSeats ? id.seat : id.view.seat;

        if (statuses.invisible) {
            return 'x';
        } else if (statuses.handicap) {
            return `H${number}`;
        } else {
            return number;
        }
    };

    const canDoAction = () => {
        if (editor.state === 'write') {
            if (seat.attr[editor.doAction] === true) {
                delete seat.attr[editor.doAction];
                if (editor.doAction !== 'handicap') {
                    seat.attr.cr_seat = Number(seat.attr.cr_seat) - 100;
                }
            } else {
                seat.attr[editor.doAction] = true;
                if (editor.doAction !== 'handicap') {
                    seat.attr.cr_seat = Number(seat.attr.cr_seat) + 100;
                }
            }
            setStatuses({
                ...statuses,
                [editor.doAction]: seat.attr[editor.doAction]
            });
        }
    };

    return (
        <div
            onClick={canDoAction}
            style={seatStyles}
            className={`cr-seat ${statuses.invisible ? 'invisible' : ''} ${
                statuses.disabled ? 'permablock' : ''
            } ${statuses.handicap ? 'handicap' : ''} ${
                editor.state === 'write' ? 'writeable' : ''
            }`}
        >
            <div>{getSeatText()}</div>
        </div>
    );
};

export default Seat;
