/** @format */

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    applicationId: process.env.REACT_APP_APPLICATION_ID,
    projectId: process.env.REACT_APP_PROJECT_ID,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
});

// if (window.location.hostname === 'localhost') {
//     app.firestore().settings({
//         host: 'localhost:8080',
//         ssl: false
//     });
// }

export const writeDoc = async (collection, docId, record, merge = true) => {
    if (docId != null) {
        const resp = await app
            .firestore()
            .collection(collection)
            .doc(docId)
            .set(record, { merge: merge });
        return resp;
    } else {
        const resp = await app.firestore().collection(collection).add(record);
        return resp;
    }
};

export const updateMap = async (update, docId) => {
    try {
        await app
            .firestore()
            .collection('mapBuilds')
            .doc(docId)
            .update({
                production: false,
                json: {
                    map: update
                }
            });
    } catch (err) {
        console.error(err);
    }
};

export const updateMapState = async (docId) => {
    try {
        await app.firestore().collection('mapBuilds').doc(docId).update({
            production: true
        });
    } catch (err) {
        console.error(err);
    }
};

export default app;
