import { Modal, Form, Input, Select, InputNumber } from 'antd';
import React, { useState } from 'react';
import newElement from '../services/elementSchema';
// import { updateMap } from '../base';

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};
const AddElementModal = ({ visible, setVisible, updateMap, seating }) => {
    const [form] = Form.useForm();
    const [showLocation, setShowLocation] = useState(false);

    const onFinish = async () => {
        console.log(form.getFieldsValue());
        const copy = { ...seating };
        console.log(copy);

        const vals = form.getFieldsValue();

        const newLevel = {
            attr: {
                name: vals.name,
                cr_level: copy.level ? copy.level.length + 1 : 1,
                styles: JSON.stringify({
                    display: 'flex',
                    position: 'relative'
                })
            },
            section: [
                {
                    attr: {
                        number: 1,
                        cr_section: 1
                    },
                    ...newElement(vals)
                }
            ]
        };
        // console.log(newLevel);
        if (vals.location === 'top') {
            copy.level.unshift(newLevel);
        } else {
            copy.level.push(newLevel);
        }

        updateMap(copy);
        // await updateMap(builder.mapDoc.json.map, builder.mapDoc.id);
        setVisible(false);
    };

    return (
        <Modal
            title='Add a new element'
            visible={visible}
            onOk={onFinish}
            onCancel={() => setVisible(false)}
        >
            <Form
                {...layout}
                form={form}
                name='new-element'
                initialValues={{ height: 0, width: 0 }}
                onFinish={onFinish}
            >
                <Form.Item
                    name='positioning'
                    label='Positioning'
                    rules={[{ required: true }]}
                >
                    <Select onChange={setShowLocation}>
                        <Option value='relative'>Relative</Option>
                    </Select>
                </Form.Item>
                {showLocation === 'relative' && (
                    <Form.Item
                        name='location'
                        label='Where does it go?'
                        rules={[{ required: true }]}
                    >
                        <Select>
                            <Option value='top'>Top of the page</Option>
                            <Option value='bottom'>Bottom of the page</Option>
                        </Select>
                    </Form.Item>
                )}
                <Form.Item
                    name='name'
                    label='Element Name'
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='height'
                    label='Height'
                    rules={[{ required: true }]}
                >
                    <InputNumber
                        formatter={(value) => `${value}px`}
                        parser={(value) => value.replace('px', '')}
                    />
                </Form.Item>
                <Form.Item
                    name='width'
                    label='Width'
                    rules={[{ required: true }]}
                >
                    <InputNumber
                        formatter={(value) => `${value}px`}
                        parser={(value) => value.replace('px', '')}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddElementModal;
