import React from 'react';
import { Layout, Checkbox, Typography, Button } from 'antd';
import LevelEditor from './LevelEditor';
import { Link } from "react-router-dom";

const { Sider } = Layout;
const { Title } = Typography;

const Sidebar = ({ visSettings, changeVisSettings }) => {
    const updateVisNums = (key, evt) => {
        changeVisSettings({
            ...visSettings,
            [key]: evt.target.checked
        });
    };

    return (
        <Sider
            width={'20%'}
            style={{
                background: '#fff',
                height: '100vh',
                overflow: 'auto'
            }}
            id='main-sider'
        >
            <div
                style={{
                    margin: '20px'
                }}
            >
                <Button
                    type='dashed'
                    block
                    // onClick={openEditor}
                >
                    <Link to='builder'>Open Builder</Link>
                </Button>
            </div>

            <div
                style={{
                    margin: '15px 0px 15px 20px'
                }}
            >
                <Title level={5}>View Numbers</Title>
                {Object.keys(visSettings).map((key) => (
                    <div key={key}>
                        <Checkbox
                            onChange={(e) => updateVisNums(key, e)}
                            checked={visSettings[key]}
                        >
                            {key}
                        </Checkbox>
                        <br />
                    </div>
                ))}
            </div>
            <div>
                <Title style={{ marginLeft: '20px' }} level={5}>
                    Map Breakdown
                </Title>
                <div style={{ padding: '15px 15px 0px 15px' }}>
                    <LevelEditor />
                </div>
            </div>
        </Sider>
    );
};

export default Sidebar;
