import React, { useContext } from 'react';
import { Collapse, Button } from 'antd';
import {
    ColumnHeightOutlined,
    VerticalAlignMiddleOutlined,
    AlignLeftOutlined,
    AlignRightOutlined
} from '@ant-design/icons';

import { updateMap } from '../base';
import { BuilderContext } from '../App';
import RowEditor from './RowEditor';
const { Panel } = Collapse;

const SectionEditor = ({ levelCr, lIndex, section, sIndex }) => {
    const builder = useContext(BuilderContext);

    const renumberRows = async (distanced, useLetters, attrType) => {
        const multiplier = distanced ? 2 : 1;
        const A = 65;
        const Z = 90;
        const copy = { ...builder.mapDoc.json.map };

        const calcLetter = (i) => {
            const everyother = i * multiplier;
            if (i === 0) {
                return String.fromCharCode(A);
            } else if (i < Z) {
                return String.fromCharCode(everyother + A);
            } else {
                const single = String.fromCharCode(everyother + A);
                return single + single;
            }
        };

        copy.level[lIndex].section[sIndex].row.forEach((row, i) => {
            copy.level[lIndex].section[sIndex].row[i] = {
                ...row,
                attr: {
                    ...row.attr,
                    [attrType]: useLetters
                        ? calcLetter(i)
                        : `${i * multiplier + 1}`
                }
            };
        });
        // console.log(copy.level[lIndex].section[sIndex].row);
        await updateMap(copy, builder.mapDoc.id);
    };

    const toggleSDInSeats = async (distanced) => {
        const copy = { ...builder.mapDoc.json.map };

        const formatSeatNum = (num) => {
            const crNum = Number(num);
            if (distanced) {
                return `${crNum * 10}`;
            } else if (crNum <= 10 && !distanced) {
                return `${crNum / 10}`;
            }

            return `${crNum}`;
        };

        copy.level[lIndex].section[sIndex].row.forEach((row, i) => {
            copy.level[lIndex].section[sIndex].row[i].seat = row.seat.map(
                (seat, i) => ({
                    ...seat,
                    attr: {
                        ...seat.attr,
                        cr_seat: formatSeatNum(seat.attr.cr_seat)
                    }
                })
            );
        });

        // console.log(copy.level[lIndex].section[sIndex].row);
        await updateMap(copy, builder.mapDoc.id);
    };

    const reOrderSeatNums = async (backward, attrType) => {
        const copy = { ...builder.mapDoc.json.map };
        copy.level[lIndex].section[sIndex].row.forEach((row, i) => {
            const seatsLength = row.seat.length;

            copy.level[lIndex].section[sIndex].row[i].seat = row.seat.map(
                (seat, i) => ({
                    ...seat,
                    attr: {
                        ...seat.attr,
                        [attrType]: backward ? `${seatsLength - i}` : `${i + 1}`
                    }
                })
            );
        });

        await updateMap(copy, builder.mapDoc.id);
    };

    return (
        <Collapse key={`l${levelCr}s${section.attr.cr_section}`}>
            <Panel
                key={`l${levelCr}s${section.attr.cr_section}`}
                header={`Section ${section.attr.cr_section} - ${section.attr.number}`}
            >
                <div
                    style={{
                        marginBottom: '10px'
                    }}
                >
                    <div>
                        <span>Row CR: </span>
                        <Button
                            onClick={() => renumberRows(true, false, 'cr_row')}
                            shape='circle'
                            icon={<ColumnHeightOutlined />}
                        />
                        &nbsp;
                        <Button
                            onClick={() => renumberRows(false, false, 'cr_row')}
                            shape='circle'
                            icon={<VerticalAlignMiddleOutlined />}
                        />
                    </div>
                    <br />
                    <div>
                        <span>Row View (numbers): </span>
                        <Button
                            onClick={() => renumberRows(true, false, 'name')}
                            shape='circle'
                            icon={<ColumnHeightOutlined />}
                        />
                        &nbsp;
                        <Button
                            onClick={() => renumberRows(false, false, 'name')}
                            shape='circle'
                            icon={<VerticalAlignMiddleOutlined />}
                        />
                    </div>
                    <br />
                    <div>
                        <span>Row View (letters): </span>
                        <Button
                            onClick={() => renumberRows(true, true, 'name')}
                            shape='circle'
                            icon={<ColumnHeightOutlined />}
                        />
                        &nbsp;
                        <Button
                            onClick={() => renumberRows(false, true, 'name')}
                            shape='circle'
                            icon={<VerticalAlignMiddleOutlined />}
                        />
                    </div>
                    <br />
                    <div>
                        {/* <Title level={5}>Seat CR</Title> */}
                        <span>Seat CR: </span>
                        <Button
                            onClick={() => reOrderSeatNums(false, 'cr_seat')}
                            shape='circle'
                            icon={<AlignLeftOutlined />}
                        />
                        &nbsp;
                        <Button
                            onClick={() => reOrderSeatNums(true, 'cr_seat')}
                            shape='circle'
                            icon={<AlignRightOutlined />}
                        />
                    </div>
                    <br />
                    <div>
                        <span>Seat View: </span>
                        <Button
                            onClick={() => reOrderSeatNums(false, 'number')}
                            shape='circle'
                            icon={<AlignLeftOutlined />}
                        />
                        &nbsp;
                        <Button
                            onClick={() => reOrderSeatNums(true, 'number')}
                            shape='circle'
                            icon={<AlignRightOutlined />}
                        />
                    </div>
                    <br />
                    <div>
                        <span>Seat CR Distancing: </span>
                        <Button
                            onClick={() => toggleSDInSeats(true)}
                            shape='circle'
                            icon={<ColumnHeightOutlined />}
                        />
                        &nbsp;
                        <Button
                            onClick={() => toggleSDInSeats(false)}
                            shape='circle'
                            icon={<VerticalAlignMiddleOutlined />}
                        />
                    </div>
                </div>
                {section.row &&
                    section.row.map((row, i) => (
                        <RowEditor
                            key={`s${section.attr.cr_section}r${row.attr.cr_row}`}
                            indexes={{ level: lIndex, section: sIndex }}
                            sectionCr={section.attr.cr_section}
                            row={row}
                            rIndex={i}
                        />
                    ))}
            </Panel>
        </Collapse>
    );
};

export default SectionEditor;
