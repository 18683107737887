import React, { createContext, useContext, useEffect, useState } from 'react';
import DetailsLevel from '../components/DetailsLevel';
import DetailsSection from '../components/DetailsSection';
import SaveAsModal from '../components/SaveAsModal';
import { SeatMap } from '@churchreserve/cavea';
import "@churchreserve/cavea/dist/index.modern.css";
import Sidebar from '../components/Sidebar';
import { Alert, Button, message, PageHeader, Popconfirm, Tag } from 'antd';
import { BuilderContext } from '../App';
import app, { updateMapState } from '../base';
import { jsToXml } from '../hooks/convertor';

const DetailsContext = createContext({});

const Editor = ({ startOver }) => {
    const builder = useContext(BuilderContext);
    const [numberingSettings, setNumberingSettings] = useState({
        view: false,
        section: true,
        row: true,
        seat: true
    });
    const [details, setDetails] = useState({});
    const [saveAsVis, setSaveAsVis] = useState(false);
    const [error, setError] = useState(false);
    const [mapXml, setMapXml] = useState(null);

    useEffect(() => {
        if (builder.mapDoc && builder.mapDoc.json) {
            // console.log(builder.mapDoc);
            const xmlText = jsToXml(builder.mapDoc.json);
            setMapXml(null);
            setTimeout(() => {
                setMapXml(xmlText);
            }, 250);
        }
    }, [builder.mapDoc]);

    const doNothing = () => {};

    const updateStorage = async ({ startOver }) => {
        if (!builder.mapDoc.mapUrl) {
            const resp = writeToStorage(
                builder.mapDoc.church,
                builder.mapDoc.mapName
            );
            console.log(resp);
            if (resp) {
                message.success('Seating map saved to storage');
            }
        } else {
            const [dir, file] = builder.mapDoc.mapUrl
                .split('/o/')[1]
                .split('?')[0]
                .split('%2F');
            const resp = await writeToStorage(dir, file);
            console.log(resp);
            if (resp) {
                message.success('Seating map saved to storage');
            }
        }
        await updateMapState(builder.mapDoc.id);
    };

    const writeToStorage = async (directory, fileName) => {
        setError(false);
        const formatted = fileName.replace(/ /g, '_');

        let newXml = mapXml.replace('<root', '<map');
        newXml = newXml.replace('</root>', '</map>');
        const xmlDoc = new window.Blob([newXml], { type: 'text/xml' });
        try {
            await app
                .storage()
                .ref()
                .child(`${directory}/${formatted}`)
                .put(xmlDoc, { contentType: 'text/xml' });
            return await app
                .storage()
                .ref()
                .child(`${directory}/${formatted}`)
                .getDownloadURL();
        } catch (err) {
            console.log(err);
            setError(true);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                overflow: 'hidden',
                height: '100%',
                position: 'relative'
            }}
        >
            {builder.mapDoc && (
                <SaveAsModal
                    visible={saveAsVis}
                    cancel={() => setSaveAsVis(false)}
                    currentName={builder.mapDoc.mapName}
                    saveFile={writeToStorage}
                />
            )}
            <DetailsContext.Provider value={{ setDetails }}>
                {builder.mapDoc && builder.mapDoc.json && (
                    <Sidebar
                        visSettings={numberingSettings}
                        changeVisSettings={setNumberingSettings}
                    />
                )}
                <div
                    style={{
                        overflow: 'hidden',
                        height: '100vh',
                        background: '#f0f2f5',
                        width: '80%'
                    }}
                >
                    {details.level && (
                        <DetailsLevel lIndex={details.level.index} />
                    )}
                    {details.section && (
                        <DetailsSection
                            lIndex={details.section.level}
                            sIndex={details.section.index}
                        />
                    )}
                    {builder.mapDoc && builder.mapDoc && (
                        <div style={{ padding: '10px 10px 0px 10px' }}>
                            <PageHeader
                                style={{
                                    boxShadow: '2px 4px 8px #0000001a',
                                    zIndex: '999'
                                }}
                                ghost={false}
                                title={builder.mapDoc.mapName}
                                subTitle={
                                    builder.mapDoc.mapUrl
                                        ? builder.mapDoc.mapUrl
                                              .split('/o/')[1]
                                              .split('?')[0]
                                              .replace('%2F', '/')
                                        : ''
                                }
                                tags={
                                    (builder.mapDoc.production && (
                                        <Tag color='blue'>Current</Tag>
                                    )) || <Tag color='volcano'>Out of date</Tag>
                                }
                                extra={[
                                    <Popconfirm
                                        key='update'
                                        placement='bottom'
                                        title={'Are you sure?'}
                                        onConfirm={updateStorage}
                                        okText='Yes'
                                        cancelText='No'
                                    >
                                        <Button type='dashed' danger>
                                            Save to Church
                                        </Button>
                                    </Popconfirm>,
                                    <Button
                                        key='saveAs'
                                        onClick={() => setSaveAsVis(true)}
                                    >
                                        Save As...
                                    </Button>,
                                    <Button key='new' onClick={startOver}>
                                        New Map
                                    </Button>
                                ]}
                            />
                            {error && (
                                <Alert
                                    message='There was a problem saving this map to storage'
                                    type='error'
                                />
                            )}
                        </div>
                    )}
                    {mapXml && (
                        <SeatMap
                            reservations={[]}
                            selections={[]}
                            onSelect={(values) => doNothing()}
                            mapTemplate={mapXml}
                            openSeats={(values) => doNothing()}
                            showNumbers={numberingSettings}
                        />
                    )}
                </div>
            </DetailsContext.Provider>
        </div>
    );
};

export default Editor;
