import xml2js from 'xml2js';

export const jsToXml = (object) => {
    const { map } = object;
    const builder = new xml2js.Builder({ attrkey: 'attr' });
    const xmlStr = builder.buildObject(map);
    return xmlStr.replace(/&#xA;/g, ' ');
};

export const xmlToJs = async (xmlString) => {
    const parser = new xml2js.Parser({ attrkey: 'attr' });
    try {
        if (xmlString.length) {
            const object = await parser.parseStringPromise(xmlString);
            console.log('json map ', object);
            return object;
        }
    } catch (e) {
        console.log(e);
    }
};
