import React from 'react';
import { Collapse } from 'antd';
import Attr from './Attr';
import ChildrenList from './ChildrenList';

const { Panel } = Collapse;

const Details = ({ json, type, index, updateJson }) => {
    let nJson = { ...json };

    if (type === 'Text') {
        console.log(json);
    }

    const updateAttr = (objectKey, value) => {
        // console.log(objectKey, value);
        const temp = {
            ...nJson,
            attr: {
                ...nJson.attr,
                [objectKey]: value
            }
        };
        updateJson(temp);
        nJson = { ...temp };
    };

    const updateList = (list, isRect) => {
        const temp = {
            ...nJson
        };
        if (isRect) {
            temp.rect = list;
        } else {
            temp[getType().toLowerCase()] = list;
        }

        updateJson(temp, index);
        nJson = { ...temp };
    };

    const updateParam = (objectKey, value) => {
        const temp = {
            ...nJson,
            [objectKey]: value
        };

        updateJson(temp);
        nJson = { ...temp };
    };

    const getType = () => {
        if (nJson.level) {
            return 'Level';
        } else if (nJson.section) {
            return 'Section';
        } else if (nJson.svg) {
            return 'SVG';
        } else if (nJson.row) {
            return 'Row';
        } else if (nJson.seat) {
            return 'Seat';
        } else if (nJson.text) {
            return 'Text';
        } else if (nJson.rect) {
            return 'Element';
        }
    };

    return (
        <div className='details'>
            <Collapse destroyInactivePanel>
                <Panel
                    header={`${type}: ${
                        nJson.attr.number || nJson.attr.name || ''
                    }`}
                    key={`${type}${index + 1}`}
                >
                    <Collapse destroyInactivePanel>
                        <Panel
                            onChange={(e) => e.preventDefault()}
                            header='Details'
                            key={`Details${type}${index + 1}`}
                        >
                            {Object.keys(nJson.attr).map((objKey) => (
                                <Attr
                                    value={nJson.attr[objKey]}
                                    objectKey={objKey}
                                    key={objKey}
                                    updateParam={updateAttr}
                                />
                            ))}
                            {nJson._ && (
                                <Attr
                                    value={nJson._}
                                    objectKey='_'
                                    key='_'
                                    updateParam={updateParam}
                                />
                            )}
                        </Panel>
                    </Collapse>
                    <ChildrenList
                        list={
                            nJson.level ||
                            nJson.section ||
                            nJson.svg ||
                            nJson.row ||
                            nJson.seat ||
                            nJson.text
                        }
                        secondList={nJson.rect || []}
                        type={getType()}
                        updateJson={updateList}
                    />
                </Panel>
            </Collapse>
        </div>
    );
};

export default Details;
