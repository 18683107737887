import { Checkbox, Input, InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { debounce } from 'debounce';

const { TextArea } = Input;
const { Option } = Select;

const Attr = ({ value, objectKey, updateParam }) => {
    const [fieldVal, setFieldVal] = useState(value);

    useEffect(() => {
        if (value) {
            console.log('val', value);
            setFieldVal(value);
        }
    }, [value]);

    const executeUpdate = (e) => {
        if ((e.target && !e.target.value) || !e) {
            return;
        }

        if (objectKey === 'styles') {
            try {
                const jsonStyles = `{${e.target.value.replaceAll('\n', ',')}}`;
                setFieldVal(jsonStyles);

                /* this parse doens't format anything - it's a quality check. The data passed upward is a string */
                JSON.parse(jsonStyles);
                updateParam(objectKey, jsonStyles);
            } catch (err) {
                console.log('styles json not correct');
            }
        } else if (objectKey !== 'styles') {
            setFieldVal((e.target && e.target.value) || Number(e));
            updateParam(objectKey, (e.target && e.target.value) || Number(e));
        }
    };

    const updateJsonDeb = debounce((e) => {
        executeUpdate(e);
    }, 400);

    const updateJson = (e) => {
        executeUpdate(e);
    };

    const quickStyleUpdate = (e, key) => {
        console.log(e);
        // need to add rotate functionality
        let val = (e.target && e.target.value) || e;
        if (key === 'top' || key === 'left') {
            val += 'px';
        } else if (key === 'transform') {
            val = `rotate(${val}deg)`;
        }

        const json = JSON.parse(fieldVal);
        json[key] = val;
        const stylesStr = JSON.stringify(json);
        console.log(stylesStr);
        setFieldVal(stylesStr);

        updateParam('styles', stylesStr);
    };

    const formatStyles = (text) => {
        let temp = text.substring(1);
        temp = temp.slice(0, -1);
        temp = temp.replaceAll(',', '\n');
        return temp;
    };

    const formatRotate = (text) => {
        if (!text) {
            return 0;
        }
        const part1 = text.split('(')[1];
        const num = part1.split('deg')[0];
        return Number(num);
    };

    const quickStyles = (stylesText) => {
        const stylesJson = JSON.parse(stylesText);

        return (
            <>
                <div>
                    <div style={{ paddingTop: '5px' }}>
                        <label htmlFor={'position'}>Position</label>&nbsp;
                        <Select
                            defaultValue={stylesJson.position}
                            size='small'
                            onChange={(e) => quickStyleUpdate(e, 'position')}
                        >
                            <Option value='relative'>relative</Option>
                            <Option value='absolute'>absolute</Option>
                        </Select>
                    </div>
                    <div style={{ paddingTop: '5px' }}>
                        <label htmlFor={'top'}>Top</label>&nbsp;
                        <InputNumber
                            size='small'
                            defaultValue={stylesJson.top || 0}
                            onChange={(e) => quickStyleUpdate(e, 'top')}
                        />
                    </div>
                    <div style={{ paddingTop: '5px' }}>
                        <label htmlFor={'left'}>Left</label>&nbsp;
                        <InputNumber
                            size='small'
                            defaultValue={stylesJson.left || 0}
                            onChange={(e) => quickStyleUpdate(e, 'left')}
                        />
                    </div>
                    <div style={{ paddingTop: '5px' }}>
                        <label htmlFor={'transform'}>Rotate</label>&nbsp;
                        <InputNumber
                            size='small'
                            defaultValue={
                                formatRotate(stylesJson.transform) || 0
                            }
                            onChange={(e) => quickStyleUpdate(e, 'transform')}
                        />
                    </div>
                </div>
                <br />
            </>
        );
    };

    const renderInput = () => {
        if (typeof fieldVal === 'number') {
            return (
                <InputNumber
                    size='small'
                    name={objectKey}
                    defaultValue={value}
                    onChange={updateJsonDeb}
                />
            );
        } else if (objectKey === 'styles') {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        alignItems: 'flex-start'
                    }}
                >
                    <TextArea
                        size='small'
                        rows={2}
                        name={objectKey}
                        value={formatStyles(fieldVal)}
                        onChange={(e) => {
                            console.log(e.target.value);
                            updateJson(e);
                        }}
                    />
                    {value && quickStyles(value)}
                </div>
            );
        } else if (typeof fieldVal === 'boolean') {
            return (
                <Checkbox
                    name={objectKey}
                    defaultChecked={value}
                    onChange={updateJsonDeb}
                />
            );
        } else {
            return (
                <Input
                    size='small'
                    name={objectKey}
                    defaultValue={value}
                    onChange={updateJsonDeb}
                />
            );
        }
    };

    return (
        <div className='key-value'>
            <label htmlFor={objectKey}>{objectKey}</label>&nbsp;
            {renderInput()}
        </div>
    );
};

export default Attr;
