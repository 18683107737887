/** @format */

import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    List,
    Cascader,
    Card,
    Typography,
    Input,
    Space,
    Tag
} from 'antd';
import app, { writeDoc } from '../base';
import { BuilderContext } from '../App';
import getTextFile from '../hooks/getTextFile';
import { xmlToJs } from '../hooks/convertor';
import CreateNewMapModel from '../modals/createNewMapModel';
import mapSchema from '../services/mapSchema';
import { useNavigate } from "react-router-dom";

const { Search } = Input;
const { Text } = Typography;

function SeatingMapSelect() {
    const [builds, setBuilds] = useState([]);
    const [filteredBuilds, setFilteredBuilds] = useState([]);
    const [churches, setChurches] = useState([]);
    const [titleText, setTitleText] = useState('Select a seating map');
    const [newMapSelect, setNewMapSelect] = useState(false);
    const [newBuilder, setNewBuilder] = useState({});
    const [newMapVis, setNewMapVis] = useState(false);
    const builder = useContext(BuilderContext);
    const navigate = useNavigate();

    useEffect(() => {
        app.firestore()
            .collection('mapBuilds')
            .orderBy('church')
            .get()
            .then((docs) => {
                const temp = [];
                docs.forEach((doc) => temp.push({ ...doc.data(), id: doc.id }));
                // temp.sort((a, b) => (a.id > b.id ? a : b));
                setBuilds(temp);
                setFilteredBuilds(temp);
            })
            .catch((error) => console.error(error));
    }, []);

    const lookupChurches = async () => {
        app.firestore()
            .collection('accounts')
            .get()
            .then((snap) => {
                setChurches(
                    snap.docs.map((doc) => {
                        const church = doc.data();
                        return {
                            value: doc.id,
                            label: church.name,
                            children: church.maps
                                ? church.maps.map((item) => ({
                                        id: item.id,
                                        value: item.url,
                                        label: item.name,
                                  }))
                                : []
                        };
                    })
                );
            })
            .catch((error) => console.error(error));
    };

    const findMap = () => {
        setTitleText('Select the church and the map');
        setNewMapSelect(!newMapSelect);

        if (churches.length === 0 && !newMapSelect === true) {
            lookupChurches();
        }
    };

    const selectRow = (id, dest) => {
        builder.setMapKey(id);
        if (dest === 'editor') {
            navigate(`/command/${id}`);
        } else if (dest === 'builder') {
            navigate(`/command/${id}/builder`);
        }
    };

    function onChange(value, selectedOptions) {
        console.log('opt', selectedOptions);
        setNewBuilder({
            account: value[0],
            mapId: selectedOptions[1].id,
            mapName: selectedOptions[1].label,
            mapUrl: selectedOptions[1].value
        });
    }

    const createBuilder = async () => {
        const xmlText = await getTextFile(newBuilder.mapUrl);
        const mapJson = await xmlToJs(xmlText);
        const fireDoc = await writeDoc('mapBuilds', null, {
            ...newBuilder,
            json: mapJson,
            production: true
        });
        builder.setMapKey(fireDoc.id);
        selectRow(fireDoc.id, 'builder');
    };

    const addNewMap = () => {
        console.log('create new');
        setNewMapVis(true);
        if (churches.length === 0) {
            lookupChurches();
        }
    };

    const finalizeNewMap = async (newMapDoc) => {
        console.log(newMapDoc);
        const fireDoc = await writeDoc('mapBuilds', null, {
            ...newMapDoc,
            json: mapSchema
        });
        builder.setMapKey(fireDoc.id);
        selectRow(fireDoc.id, 'builder');
    };

    function filter(inputValue, path) {
        return path.some(
            (option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >
                -1
        );
    }

    const onSearch = (e) => {
        console.log(e.target.value);
        if (e.target.value === '') {
            setFilteredBuilds(builds);
        } else {
            setFilteredBuilds(
                builds.filter((item) => item.church.includes(e.target.value))
            );
        }
    };

    const deleteBuild = async (id) => {
        app.firestore()
            .collection('mapBuilds')
            .doc(id)
            .delete()
            .then((resp) => {
                app.firestore()
                    .collection('mapBuilds')
                    .get()
                    .then((docs) => {
                        const temp = [];
                        docs.forEach((doc) =>
                            temp.push({ ...doc.data(), id: doc.id })
                        );
                        // temp.sort((a, b) => (a.id > b.id ? a : b));
                        setBuilds(temp);
                        setFilteredBuilds(temp);
                    })
                    .catch((error) => console.error(error));
            })
            .catch((error) => console.error(error));
    };

    return (
        <div style={{ height: '100vh', overflow: 'scroll' }}>
            <CreateNewMapModel
                visible={newMapVis}
                setVisible={setNewMapVis}
                churches={churches}
                newMapData={finalizeNewMap}
            />
            <Row style={{ paddingTop: '30px' }}>
                <Col xs={{ span: 20, offset: 2 }} md={{ span: 12, offset: 6 }}>
                    <Card
                        title={titleText}
                        extra={[
                            <Button key='find' type='primary' onClick={findMap}>
                                Find a Map
                            </Button>,
                            <Button key='add' onClick={addNewMap}>
                                Create a Map
                            </Button>
                        ]}
                    >
                        {!newMapSelect ? (
                            <>
                                <Space
                                    direction='vertical'
                                    style={{ width: '100%' }}
                                >
                                    <Search
                                        placeholder='search for map'
                                        onChange={onSearch}
                                    />
                                    <List
                                        itemLayout='horizontal'
                                        dataSource={filteredBuilds}
                                        bordered
                                        renderItem={(item) => (
                                            <List.Item
                                                className='cursor-pointer'
                                                actions={[
                                                    <Tag
                                                        key='mapState'
                                                        color={
                                                            item.production
                                                                ? 'blue'
                                                                : 'volcano'
                                                        }
                                                    >
                                                        {item.production
                                                            ? 'Current'
                                                            : 'Out of date'}
                                                    </Tag>,
                                                    <a
                                                        onClick={() =>
                                                            selectRow(
                                                                item.id,
                                                                'editor'
                                                            )
                                                        }
                                                        key='editor'
                                                    >
                                                        Editor
                                                    </a>,
                                                    <a
                                                        onClick={() =>
                                                            selectRow(
                                                                item.id,
                                                                'builder'
                                                            )
                                                        }
                                                        key='builder'
                                                    >
                                                        Builder
                                                    </a>,
                                                    <Text
                                                        key='delete'
                                                        type='danger'
                                                        onClick={() =>
                                                            deleteBuild(item.id)
                                                        }
                                                    >
                                                        Delete
                                                    </Text>
                                                ]}
                                            >
                                                <Text mark>{item.church}</Text>
                                                {' - '}
                                                {item.mapName}
                                            </List.Item>
                                        )}
                                    />
                                </Space>
                            </>
                        ) : (
                            <>
                                <Cascader
                                    style={{
                                        width: '100%',
                                        marginBottom: '15px'
                                    }}
                                    options={churches}
                                    onChange={onChange}
                                    placeholder='Select a Church'
                                    showSearch={{ filter }}
                                />
                                <Button onClick={createBuilder}>
                                    Create Builder for this map
                                </Button>
                            </>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default SeatingMapSelect;
