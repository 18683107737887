import React, { useState, createContext } from 'react';
import './App.scss';
import CommandCenter from './screens/CommandCenter';
import SeatingMapSelect from './screens/SeatingMapSelect';
import { Routes, Route } from "react-router-dom";

export const BuilderContext = createContext({});

const App = () => {
    const [mapKey, setMapKey] = useState('');
    const [mapDoc, setMapDoc] = useState();

    return (
        <BuilderContext.Provider
            value={{
                setMapKey,
                mapKey,
                setMapDoc,
                mapDoc
            }}
        >
            <div
                style={{
                    background: '#f0f2f5'
                }}
            >
                <Routes>
                    <Route path="/" element={<SeatingMapSelect />} />
                    <Route path="command/:uuid/*" element={<CommandCenter />} />
                </Routes>
            </div>
        </BuilderContext.Provider>
    );
};

export default App;
