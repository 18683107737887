import React, { useContext, useEffect, useState } from 'react';
import { Typography } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import removeEncoding from './removeEncoding';
import Seat from './Seat';
import { EditorContext } from '../../screens/Builder';
import { updateMap } from '../../base';

const { Paragraph } = Typography;

const Row = ({ row, identity, bulkSeatStyles, removeRow }) => {
    const [rowStyles, setRowStyles] = useState({});
    const [label, setLabel] = useState(row.attr.name);
    const editor = useContext(EditorContext);
    const [id] = useState({
        ...identity,
        row: row.attr.cr_row,
        view: { ...identity.view, row: row.attr.name }
    });

    useEffect(() => {
        if (row.attr.styles) {
            setRowStyles(removeEncoding(row.attr.styles));
        }

        setLabel(row.attr.name);
    }, [row.attr]);

    useEffect(() => {
        if (label !== row.attr.name) {
            row.attr.name = label;
            updateMap(editor.seatingMap, editor.mapId);
        }
    }, [label]);

    const deleteSeat = () => {
        console.log('delete seat');
        if (row.seat.length > 1) {
            row.seat.pop();
            updateMap(editor.seatingMap, editor.mapId);
        } else if (row.seat.length <= 1) {
            removeRow();
        }
    };

    const addSeat = () => {
        console.log('add seat');
        row.seat.push({
            attr: {
                number: row.seat.length + 1,
                cr_seat: row.seat.length + 1
            }
        });
        updateMap(editor.seatingMap, editor.mapId);
    };

    return (
        <div
            style={{ ...rowStyles, transform: bulkSeatStyles.rotate }}
            className='cr-row'
        >
            <div className='cr-row-label'>
                <Paragraph editable={{ onChange: setLabel }}>{label}</Paragraph>
            </div>
            <div
                className='seats'
                style={{ justifyContent: bulkSeatStyles.alignment }}
            >
                {row.seat.map((seat, i) => (
                    <Seat key={`seat_${i}`} seat={seat} identity={id} />
                ))}
                <div className='cr-row-seat-add-btn' onClick={() => addSeat()}>
                    <PlusCircleOutlined />
                </div>
                <div
                    className='cr-row-seat-add-btn'
                    onClick={() => deleteSeat()}
                >
                    <MinusCircleOutlined />
                </div>
            </div>
        </div>
    );
};

export default Row;
