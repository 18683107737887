import React, { useContext } from 'react';
import { BuilderContext } from '../App';
import { updateMap } from '../base';
import DetailsForm from './DetailsForm';

const DetailsLevel = ({ lIndex }) => {
    const builder = useContext(BuilderContext);

    const onSave = async (values) => {
        builder.mapDoc.json.map.level[lIndex].attr = values;
        await updateMap(builder.mapDoc.json.map, builder.mapDoc.id);
    };

    return (
        <DetailsForm
            details={builder.mapDoc.json.map.level[lIndex].attr}
            attrType='level'
            viewParam='name'
            onSave={onSave}
        />
    );
};

export default DetailsLevel;
