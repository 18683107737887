import { PageHeader, Layout, Button, Switch, Radio, notification } from 'antd';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Level from '../components/Cavea2/Level';
import { MapInteractionCSS } from 'react-map-interaction';
import AddLevelModal from '../modals/addLevelModal';
import { BuilderContext } from '../App';
import BuilderSidebar from '../components/Cavea2/BuilderSidebar';
import { updateMap } from '../base';
import AddElementModal from '../modals/addElementModal';
import Explorer from '../components/Explorer';
const { Sider } = Layout;

export const EditorContext = createContext({});

const Builder = ({ goBack }) => {
    const [seatingMap, setSeatingMap] = useState({});
    const [modalVis, setModalVis] = useState(false);
    const builder = useContext(BuilderContext);
    const [activeEdit, setActiveEdit] = useState([]);
    const [mapId, setMapId] = useState();
    const [showCRSeats, setShowCRSeats] = useState();
    const [state, setState] = useState('read');
    const [doAction, setDoAction] = useState();
    const [movingItem, setMovingItem] = useState(false);
    const [mapPos, setMapPos] = useState({
        scale: 1,
        translation: { x: 0, y: 0 }
    });
    const [explorerData, setExplorerData] = useState();

    console.log('biulder', builder);

    useEffect(() => {
        if (builder.mapDoc && builder.mapDoc.json) {
            console.log(builder.mapDoc);
            setSeatingMap(builder.mapDoc.json.map);
            setMapId(builder.mapDoc.id);
        }
    }, [builder.mapDoc, seatingMap]);

    const setupAction = (e) => {
        console.log(e.target.value);
        if (e.target.value === doAction) {
            setDoAction(false);
        } else {
            setDoAction(e.target.value);
        }
    };

    useEffect(() => {
        if (doAction) {
            setState('write');
            openNotification();
        } else {
            setState('read');
        }
    }, [doAction]);

    useEffect(() => {
        console.log(state);
    }, [state]);

    const openNotification = () => {
        const key = `open${Date.now()}`;
        const btn = (
            <Button
                type='primary'
                size='small'
                onClick={() => {
                    setDoAction(false);
                    notification.close(key);
                    console.log(seatingMap);
                    updateMap(seatingMap, mapId);
                }}
            >
                Close
            </Button>
        );
        notification.open({
            message: `Making seats ${doAction}`,
            description:
                'While this action is live you will be able to change seats. Once you are done, click Close to stop.',
            btn,
            key,
            duration: 0
        });
    };

    const toggleExplorer = (bool) => {
        setExplorerData(bool || !explorerData);
    };

    return (
        <div id='editor-window'>
            <AddLevelModal
                visible={modalVis === 'level'}
                setVisible={setModalVis}
            />
            <AddElementModal
                updateMap={setSeatingMap}
                seating={seatingMap}
                visible={modalVis === 'element'}
                setVisible={setModalVis}
            />
            <PageHeader
                className='site-page-header'
                onBack={goBack}
                title='Map Editor'
                subTitle={builder.mapName}
                extra={[
                    <Radio.Group key='actions' onChange={setupAction}>
                        <Radio.Button value='invisible'>Invisible</Radio.Button>
                        <Radio.Button value='disabled'>
                            Perma-block
                        </Radio.Button>
                        <Radio.Button value='handicap'>Handicap</Radio.Button>
                    </Radio.Group>,
                    <Button
                        key='newlevel'
                        type='primary'
                        onClick={() => setModalVis('level')}
                    >
                        New Level
                    </Button>,
                    <Button
                        key='newElement'
                        type='primary'
                        onClick={() => setModalVis('element')}
                    >
                        New Element
                    </Button>
                ]}
            />
            <EditorContext.Provider
                value={{
                    activeEdit,
                    setActiveEdit,
                    seatingMap,
                    setSeatingMap,
                    mapId,
                    showCRSeats,
                    state,
                    doAction,
                    movingItem,
                    setMovingItem,
                    explorerData,
                    setExplorerData
                }}
            >
                <Sider
                    theme='light'
                    width='250px'
                    style={{
                        overflow: 'auto',
                        height: '91vh',
                        position: 'fixed',
                        left: 0,
                        borderRight: '1px solid gray',
                        padding: '20px'
                    }}
                >
                    Show CR Numbers: <Switch onChange={setShowCRSeats} />
                    <br />
                    View Explorer:{' '}
                    <Switch
                        checked={explorerData}
                        onChange={() => toggleExplorer()}
                    />
                    {explorerData ? (
                        <Explorer json={seatingMap} mapId={mapId} />
                    ) : (
                        <BuilderSidebar />
                    )}
                </Sider>
                <div id='cavea2'>
                    <MapInteractionCSS
                        value={mapPos}
                        onChange={(value) => !movingItem && setMapPos(value)}
                    >
                        <div id='seating-map'>
                            {seatingMap.level &&
                                seatingMap.level.map((lvl, i) => (
                                    <Level
                                        level={lvl}
                                        key={`level_${i}`}
                                        indexes={{ level: i }}
                                    />
                                ))}
                        </div>
                    </MapInteractionCSS>
                </div>
            </EditorContext.Provider>
        </div>
    );
};

export default Builder;
