import React, { useContext } from 'react';
import { BuilderContext } from '../App';
import { updateMap } from '../base';
import DetailsForm from './DetailsForm';

const DetailsSection = ({ lIndex, sIndex }) => {
    const builder = useContext(BuilderContext);

    const onSave = async (values) => {
        builder.mapDoc.json.map.level[lIndex].section[sIndex].attr = values;
        console.log();
        await updateMap(builder.mapDoc.json.map, builder.mapDoc.id);
    };

    return (
        <DetailsForm
            details={builder.mapDoc.json.map.level[lIndex].section[sIndex].attr}
            attrType='section'
            viewParam='number'
            onSave={onSave}
        />
    );
};

export default DetailsSection;
