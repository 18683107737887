/** @format */

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Select } from 'antd';
import { nanoid } from 'nanoid';

const { Option } = Select;
const layout = {
    labelCol: {
        span: 8
    },
    wrapperCol: {
        span: 16
    }
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16
    }
};

function CreateNewMapModel({ visible, setVisible, churches, newMapData }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!visible) {
            setLoading(false);
        }
    }, [visible]);

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };

    const onFinish = async (values) => {
        // console.log('Success:', values);
        setLoading(true);
        const id = nanoid(8);
        newMapData({
            church: values.church,
            mapName: values.map,
            mapUrl: '',
            mapId: id,
            production: true
        });
    };

    const filter = (input, option) => {
        return (
            option.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
                0 ||
            option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    };

    return (
        <Modal
            title='Create a New Seating Map?'
            visible={visible}
            confirmLoading={loading}
            onCancel={handleCancel}
            footer={[
                <Button key='back' onClick={handleCancel}>
                    Cancel
                </Button>
            ]}
        >
            <Form {...layout} name='basic' onFinish={onFinish}>
                <Form.Item
                    label='Church'
                    name='church'
                    rules={[
                        {
                            required: true,
                            message: 'Required!'
                        }
                    ]}
                >
                    <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={filter}
                    >
                        {churches.map((church) => (
                            <Option key={church.value} value={church.value}>
                                {church.label} - {church.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label='Name'
                    name='map'
                    rules={[
                        {
                            required: true,
                            message: 'Required!'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        Create
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CreateNewMapModel;
