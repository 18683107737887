import { Modal, Form, Input, InputNumber } from 'antd';
import React, { useContext } from 'react';
import { BuilderContext } from '../App';
import { updateMap } from '../base';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};
const AddLevelModal = ({ visible, setVisible }) => {
    const [form] = Form.useForm();
    const builder = useContext(BuilderContext);

    const onFinish = async () => {
        const shortLvl = builder.mapDoc.json.map.level;

        const newLevel = {
            attr: {
                name: form.getFieldsValue().levelName,
                cr_level: shortLvl ? shortLvl.length + 1 : 1,
                styles: JSON.stringify({
                    display: 'flex',
                    position: 'relative'
                })
            },
            section: Array.from(Array(form.getFieldsValue().sections)).map(
                (item, i) => ({
                    attr: {
                        number: `${i + 1}`,
                        cr_section: i + 1,
                        styles: JSON.stringify({
                            position: 'relative',
                            alignSelf: 'flex-start'
                        })
                    }
                })
            )
        };
        // console.log(newLevel);
        builder.mapDoc.json.map.level.push(newLevel);
        console.log(builder.mapDoc.json.map);
        await updateMap(builder.mapDoc.json.map, builder.mapDoc.id);
        setVisible(false);
    };

    return (
        <Modal
            title='Add a new level'
            visible={visible}
            onOk={onFinish}
            onCancel={() => setVisible(false)}
        >
            <Form {...layout} form={form} name='new-level' onFinish={onFinish}>
                <Form.Item
                    name='levelName'
                    label='Level Name'
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='sections'
                    label='How many sections?'
                    rules={[{ required: true }]}
                >
                    <InputNumber min={1} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddLevelModal;
