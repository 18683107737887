import { useState, useEffect } from 'react';
import app from '../base';

const useBuilderSnapshot = (mapKey) => {
    const [records, setRecords] = useState([]);

    useEffect(() => {
        if (mapKey) {
            const collection = app.firestore().collection('mapBuilds');

            const unsubscribe = collection.doc(mapKey).onSnapshot((doc) => {
                setRecords(doc ? { ...doc.data(), id: doc.id } : {});
            });
            return unsubscribe;
        }
    }, [mapKey]);
    return records;
};

export default useBuilderSnapshot;
