import React, { useContext } from 'react';
import { Typography, Checkbox, Select, Button } from 'antd';
import BuilderSlider from '../BuilderSlider';
import { EditorContext } from '../../screens/Builder';
import { updateMap } from '../../base';
import elementLabelSchema from '../../services/elementLabelSchema';

const { Title } = Typography;
const { Option } = Select;

const BuilderSidebar = () => {
    const editor = useContext(EditorContext);
    // console.log('editor.seatingMap', editor.seatingMap);

    const setSectionSpacing = (value, lIndex) => {
        const copy = { ...editor.seatingMap };
        const numOfSetions = copy.level[lIndex].section.length;

        const sections = copy.level[lIndex].section.map((sect, i) => {
            if (i + 1 === numOfSetions) return sect;

            const stylesJson = sect.attr.styles
                ? JSON.parse(sect.attr.styles)
                : {};
            stylesJson.marginRight = `${value}px`;
            sect.attr.styles = JSON.stringify(stylesJson);
            return sect;
        });
        copy.level[lIndex].section = sections;
        editor.setSeatingMap(copy);
    };

    const setFloorPadding = (value, index) => {
        const copy = { ...editor.seatingMap };
        const stylesJson = JSON.parse(copy.level[index].attr.styles);
        stylesJson.padding = `${value}px`;
        copy.level[index].attr.styles = JSON.stringify(stylesJson);

        editor.setSeatingMap(copy);
    };

    const setAlignment = (e, index) => {
        const copy = { ...editor.seatingMap };
        const stylesJson = JSON.parse(copy.level[index].attr.styles);
        stylesJson.justifyContent = e.target.checked ? 'center' : 'flex-start';
        copy.level[index].attr.styles = JSON.stringify(stylesJson);
        editor.setSeatingMap(copy);
        updateMap(copy, editor.mapId);
    };

    const spaceOutRows = (e, index) => {
        const copy = { ...editor.seatingMap };

        const sections = copy.level[index].section.map((sect, i) => {
            if (sect.row && sect.row.length > 0) {
                sect.row.map((row) => {
                    const stylesJson = row.attr.styles
                        ? JSON.parse(row.attr.styles)
                        : {};
                    stylesJson.marginTop = e.target.checked ? `20px` : '0px';
                    row.attr.styles = JSON.stringify(stylesJson);
                    return row;
                });
            }
            return sect;
        });
        copy.level[index].section = sections;
        updateMap(copy, editor.mapId);
    };

    const changeNumbering = (val, index) => {
        const copy = { ...editor.seatingMap };

        if (val === 'entire_sequence') {
            const rowCount = findMaxRowCount(copy.level[index]);
            console.log('🚀 rowCount', rowCount);

            let rowStartNum = 1;
            for (let i = 0; i < rowCount; i++) {
                rowStartNum = orderRowHorizontal(copy, index, i, rowStartNum);
                console.log('🚀 rowCount', rowStartNum);
            }

            editor.setSeatingMap(copy);
            updateMap(copy, editor.mapId);
        } else if (val === 'section_count') {
            const level = copy.level[index];

            for (let i = 0; i < level.section.length; i++) {
                copy.level[index].section[i] = orderSectionTotal(
                    level.section[i]
                );
            }

            editor.setSeatingMap(copy);
            updateMap(copy, editor.mapId);
        } else if (val === 'section_row') {
            const level = copy.level[index];

            for (let i = 0; i < level.section.length; i++) {
                copy.level[index].section[i] = orderSectionWithRows(
                    level.section[i]
                );
            }

            editor.setSeatingMap(copy);
            updateMap(copy, editor.mapId);
        } else if (val === 'entire_horizontal') {
            const rowCount = findMaxRowCount(copy.level[index]);

            for (let i = 0; i < rowCount; i++) {
                orderRowHorizontal(copy, index, i, 1);
            }

            editor.setSeatingMap(copy);
            updateMap(copy, editor.mapId);
        }
    };

    const findMaxRowCount = (level) => {
        let hightestCount = 0;
        for (const section of level.section) {
            if (!section.row) {
                continue;
            }

            if (hightestCount < section.row.length) {
                hightestCount = section.row.length;
            }
        }
        return hightestCount;
    };

    const orderSectionWithRows = (section) => {
        for (let i = 0; i < section.row.length; i++) {
            let num = 0;
            let blockedNum = 100;

            section.row[i].seat = section.row[i].seat.map((seat, i) => {
                if (seat.attr.invisible || seat.attr.disabled) {
                    blockedNum += 100;
                    return {
                        ...seat,
                        attr: {
                            ...seat.attr,
                            number: blockedNum
                        }
                    };
                }

                num += 1;
                return {
                    ...seat,
                    attr: {
                        ...seat.attr,
                        number: num
                    }
                };
            });
        }
        return section;
    };

    const orderSectionTotal = (section) => {
        let newStartNum = 0;
        let blockedNum = 100;

        if (!section.row) {
            return section;
        }
        for (let i = 0; i < section.row.length; i++) {
            section.row[i].seat = section.row[i].seat.map((seat, i) => {
                if (seat.attr.invisible || seat.attr.disabled) {
                    blockedNum += 10;
                    return {
                        ...seat,
                        attr: {
                            ...seat.attr,
                            number: blockedNum
                        }
                    };
                }

                newStartNum += 1;
                return {
                    ...seat,
                    attr: {
                        ...seat.attr,
                        number: newStartNum
                    }
                };
            });
        }
        console.log('in fn', section);
        return section;
    };

    const orderRowHorizontal = (copy, lIndex, rowIndex, startNum) => {
        let newStartNum = startNum;

        const level = copy.level[lIndex];

        for (let i = 0; i < level.section.length; i++) {
            if (!level.section[i].row) {
                continue;
            }
            if (
                level.section[i].row[rowIndex] &&
                level.section[i].row[rowIndex].seat
            ) {
                level.section[i].row[rowIndex].seat = level.section[i].row[
                    rowIndex
                ].seat.map((seat, i) => ({
                    ...seat,
                    attr: {
                        ...seat.attr,
                        number: newStartNum + i
                    }
                }));
                newStartNum += level.section[i].row[rowIndex].seat.length;
            }
        }
        console.log('in fn', copy);
        return newStartNum;
    };

    const onAfterChanges = async () => {
        await updateMap(editor.seatingMap, editor.mapId);
    };

    const removePx = (stylesStr, param) => {
        if (!stylesStr) return;
        const pxValue = JSON.parse(stylesStr)[param];
        if (!pxValue) return;
        return Number(pxValue.replace('px', ''));
    };

    const parseStyle = (stylesStr, param) => {
        if (!stylesStr) return;
        const val = JSON.parse(stylesStr)[param];
        return val;
    };

    const createClassroom = (index) => {
        const copy = { ...editor.seatingMap };
        const lvl = copy.level[index];
        const stylesJson = JSON.parse(lvl.attr.styles);

        if (lvl.attr.isClassroom) {
            delete stylesJson.border;
            delete stylesJson.borderRadius;
            delete stylesJson.float;
            delete stylesJson.margin;
            delete lvl.attr.isClassroom;
            stylesJson.padding = '30px';
            lvl.attr.styles = JSON.stringify(stylesJson);

            const labelIndex = lvl.section.findIndex(
                (sect) => sect.attr.isLabel
            );
            lvl.section.splice(labelIndex, 1);
        } else {
            lvl.attr.isClassroom = true;
            stylesJson.border = `2px solid #bdbdbd`;
            stylesJson.borderRadius = `8px`;
            stylesJson.padding = `60px 30px 30px`;
            stylesJson.float = 'left';
            stylesJson.margin = '30px';
            lvl.attr.styles = JSON.stringify(stylesJson);

            const label = {
                attr: {
                    cr_section: lvl.section.length,
                    number: lvl.attr.name,
                    styles: JSON.stringify({
                        position: 'absolute',
                        top: '0px',
                        left: '0px'
                    }),
                    isLabel: true
                },
                svg: []
            };

            label.svg.push(
                elementLabelSchema({
                    width: 500,
                    height: 60,
                    fontSize: 24,
                    name: lvl.attr.name
                })
            );
            // console.log(label);
            lvl.section.unshift(label);
        }

        editor.setSeatingMap(copy);
        updateMap(copy, editor.mapId);
    };

    const removeLevel = (i) => {
        const copy = { ...editor.seatingMap };
        console.log('copy for delete', copy);
        if (copy.level.length === 1) {
            copy.level = [];
        } else {
            copy.level.splice(i, 1);
        }

        editor.setSeatingMap(copy);
        updateMap(copy, editor.mapId);
    };

    return (
        <div style={{ marginTop: '20px' }}>
            {editor.seatingMap.level &&
                editor.seatingMap.level.map((level, i) => (
                    <div key={i} style={{ marginBottom: '20px' }}>
                        <Title level={4}>{level.attr.name}</Title>
                        <Button
                            danger
                            size='small'
                            onClick={() => removeLevel(i)}
                        >
                            Delete
                        </Button>
                        {!level.attr.isClassroom && (
                            <BuilderSlider
                                title='Padding'
                                setValue={(val) => setFloorPadding(val, i)}
                                onDone={onAfterChanges}
                                initialValue={removePx(
                                    level.attr.styles,
                                    'padding'
                                )}
                                max={100}
                            />
                        )}
                        <BuilderSlider
                            title='Sections Spacing'
                            setValue={(val) => setSectionSpacing(val, i)}
                            onDone={onAfterChanges}
                            initialValue={removePx(
                                level.section[0].attr.styles,
                                'marginRight'
                            )}
                            max={200}
                        />
                        <div className='level-checkboxes'>
                            <Checkbox
                                onChange={(e) => setAlignment(e, i)}
                                defaultChecked={
                                    parseStyle(
                                        level.attr.styles,
                                        'justifyContent'
                                    ) === 'center'
                                }
                            >
                                Center Sections
                            </Checkbox>
                            <Checkbox
                                style={{ marginLeft: '0px' }}
                                onChange={(e) => spaceOutRows(e, i)}
                            >
                                Space out rows (toggle)
                            </Checkbox>
                        </div>
                        <div className='classroom-styles'>
                            {/* <Button onClick={(e) => addBorder(i)}>
                                Create Classroom (toggle)
                            </Button> */}
                            <Checkbox
                                style={{ marginLeft: '0px' }}
                                onChange={(e) => createClassroom(i)}
                                defaultChecked={level.attr.isClassroom}
                            >
                                Classroom Styles
                            </Checkbox>
                            <br />
                            <br />
                        </div>
                        <div>
                            <label htmlFor='numbering'>Numbering System</label>
                            <Select
                                defaultValue='section'
                                style={{ width: '100%' }}
                                onChange={(val) => changeNumbering(val, i)}
                                name='numbering'
                            >
                                <Option value='none'>none selected</Option>
                                <Option value='section_row'>
                                    Section then Row
                                </Option>
                                <Option value='section_count'>
                                    Total Section Count
                                </Option>
                                <Option value='entire_sequence'>
                                    Entire Level Sequence
                                </Option>
                                <Option value='entire_horizontal'>
                                    Entire Row Sequence
                                </Option>
                            </Select>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default BuilderSidebar;
